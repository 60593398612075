import React, { useEffect, useState } from "react";
import { COLORS } from "constants/Colors";
import { StyleSheet, View } from "react-native";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { PrimaryText } from "components/Common/PrimaryText";
import { ActivityIndicator } from "react-native-web";
import { useBusinessDonations } from "context/Business/BusinessDonationsContext";
import { ShadowContainer } from "components/Containers/ShadowContainer";
import { BusinessDonationCard } from "components/Cards/Business/BusinessDonationCard";
import { CustomInput } from "components/Inputs/CustomInput";
import { Button } from "components/Common/Button";
import { CustomPicker } from "components/Inputs/CustomPicker";
import { useOrganization } from "context/OrganizationContext";
import { FullModal } from "components/Modals/FullModal";
import { BusinessDonationApprovalCard } from "components/Cards/Business/BusinessDonationApprovalCard";
import {
  approveBusinessDonation,
  denyBusinessDonation,
} from "services/businesses/business-donations-service";
import MuiTablePagination from "components/Common/MuiTablePagination";
import { FiUpload } from "react-icons/fi";
import exportCSV from "utils/export-csv";
import { getUser } from "services/user-service";
import { dollarFormatter, getUsersName } from "utils/helpers";
import { DONATION_STATUSES } from "constants/Data";
import moment from "moment";

const options = [
  { value: "all", label: "All" },
  { value: "pending", label: "Pending" },
  { value: "approved", label: "Approved" },
];

export const BusinessDonations = () => {
  const navigate = useNavigate();
  const { selectedOrg } = useOrganization();
  const { recentDonations } = useBusinessDonations();

  const [search, setSearch] = useState("");
  const [selectedView, setSelectedView] = useState(options[0]?.value);
  const [ascending, setAscending] = useState(true);
  const [filtered, setFiltered] = useState([]);
  const [sorted, setSorted] = useState([]);

  const [showPendingModal, setShowPendingModal] = useState(false);

  const [loading, setLoading] = useState(false);

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);

  useEffect(() => {
    let temp = [...recentDonations];
    switch (selectedView) {
      case "pending":
        temp = temp.filter((item) => item.corporateStatus === 0);
        break;
      case "approved":
        temp = temp.filter((item) => item.corporateStatus === 1);
        break;
      default:
        break;
    }
    if (search) {
      temp = temp.filter(
        (item) =>
          item.organization?.name
            .toLowerCase()
            .includes(search.toLowerCase()) ||
          item.user?.firstName.toLowerCase().includes(search.toLowerCase()) ||
          item.user?.lastName.toLowerCase().includes(search.toLowerCase())
      );
    }
    setFiltered(temp);
  }, [selectedView, search, recentDonations]);

  const currentTableData = () => {
    const firstPageIndex = currentPage * rowsPerPage;
    const lastPageIndex = firstPageIndex + rowsPerPage;
    return filtered.slice(firstPageIndex, lastPageIndex);
  };

  if (loading) {
    return (
      <View
        style={[styles.container, { paddingTop: "80%", alignItems: "center" }]}
      >
        <ActivityIndicator
          animating={loading}
          size={"large"}
          style={styles.activity}
          color={COLORS.primary.blue}
        />
      </View>
    );
  }

  const onExportCSV = async () => {
    const exportData = await Promise.all(
      filtered.map(async (item) => {
        const {
          userId,
          amount,
          date,
          recurring,
          organization = {},
          match = 0,
          corporateStatus = 0,
          anonymous = false,
        } = item || {};

        let user;

        const setUser = (data) => {
          user = data || { firstName: "Anonymous", lastName: "" };
        };

        if (anonymous) setUser();
        else await getUser(userId, setUser);

        return {
          NAME: getUsersName(user),
          NONPROFIT: organization?.name,
          DATE: moment(date?.toDate?.()).format("MM/DD/YYYY"),
          FREQUENCY: recurring ? "Recurring" : "One-Time",
          ["AMOUNT GIVEN"]: dollarFormatter.format(amount),
          ["COMPANY MATCH"]: dollarFormatter.format(match),
          TOTAL: dollarFormatter.format(amount + match),
          STATUS: DONATION_STATUSES[corporateStatus]?.label,
        };
      })
    );

    exportCSV({
      data: exportData,
      filename: "Donations",
    });
  };

  return (
    <ShadowContainer style={styles.container}>
      <PrimaryText fontSize={32} fontWeight={900} style={styles.title}>
        Donations
      </PrimaryText>
      <View
        style={{
          zIndex: 999,
          flexDirection: "row",
          width: "100%",
          alignItems: "center",
          justifyContent: "space-between",
          marginVertical: 12,
        }}
      >
        <View
          style={{ flexDirection: "row", alignItems: "center", flexGrow: 1 }}
        >
          <CustomInput
            placeholder={"Search"}
            style={{ flexGrow: 1, maxWidth: 400 }}
            onChangeText={setSearch}
            value={search}
            backgroundColor={COLORS.primary.white}
          />

          <PrimaryText
            fontSize={14}
            fontWeight={900}
            style={{
              color: COLORS.primary.darkGray,
              marginHorizontal: 4,
              marginLeft: 24,
            }}
          >
            View
          </PrimaryText>
          <CustomPicker
            value={selectedView}
            onChange={(value) => setSelectedView(value)}
            options={options}
            style={{ maxWidth: 150, marginLeft: 3 }}
            backgroundColor={COLORS.primary.white}
          />
        </View>
        <View
          style={{
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          {/* {!selectedOrg?.autoApproveDonations && ( */}
            <Button
              title={"View Pending"}
              style={{
                marginRight: 12,
                backgroundColor: "transparent",
                borderWidth: 1,
                borderColor: COLORS.primary.error,
                text: { color: COLORS.primary.error },
              }}
              onPress={() => setShowPendingModal(true)}
            />
          {/* )} */}

          <Button
            title={"Export Report"}
            onPress={onExportCSV}
            iconLeft={
              <FiUpload
                size={18}
                color={COLORS.primary.white}
                style={{ marginRight: 6 }}
              />
            }
          />
        </View>
      </View>
      <Table
        className="table"
        style={{ borderTopLeftRadius: 12, overflow: "hidden" }}
      >
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell style={{ textAlign: "left" }}>NAME</TableCell>
            <TableCell>NONPROFIT</TableCell>
            <TableCell>DATE</TableCell>
            <TableCell>FREQUENCY</TableCell>
            <TableCell>{"AMOUNT\nGIVEN"}</TableCell>
            <TableCell>COMPANY MATCH</TableCell>
            <TableCell>TOTAL</TableCell>
            <TableCell>STATUS</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {currentTableData() &&
            currentTableData().map((el, i) => {
              return (
                <BusinessDonationCard
                  item={el}
                  key={el.id}
                  // onPress={() => console.log("el", el)}
                />
              );
            })}
        </TableBody>
      </Table>

      <MuiTablePagination
        count={filtered?.length}
        rowsPerPage={rowsPerPage}
        page={currentPage}
        setCurrentPage={setCurrentPage}
        onRowsPerPageChange={(e) => {
          setRowsPerPage(e.target.value);
          setCurrentPage(0);
        }}
      />

      {showPendingModal && (
        <FullModal
          title={"Donation Requests"}
          setShowModal={setShowPendingModal}
          style={{ height: selectedOrg?.stripeCustomerId ? undefined : 400 }}
        >
          <Table
            className="table"
            style={{
              borderTopLeftRadius: 12,
              overflow: "hidden",
            }}
          >
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell style={{ textAlign: "left" }}>NAME</TableCell>
                <TableCell>NONPROFIT</TableCell>
                <TableCell>DATE</TableCell>
                <TableCell>FREQUENCY</TableCell>
                <TableCell>AMOUNT</TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>

            {!selectedOrg?.stripeCustomerId ? (
              <View
                style={{
                  position: "absolute",
                  // bottom: 0,
                  top: 0,
                  left: 0,
                  right: 0,
                  minHeight: 400,
                  alignSelf: "center",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <PrimaryText
                  fontSize={14}
                  fontWeight={900}
                  style={{
                    color: COLORS.primary.darkGray,
                    marginHorizontal: 4,
                    textAlign: "center",
                  }}
                >
                  {
                    "To start approving matched donations,\nplease connect your payment methods in settings."
                  }
                </PrimaryText>
              </View>
            ) : (
              <TableBody>
                {recentDonations &&
                  recentDonations
                    .filter((x) => x.corporateStatus === 0)
                    .map((el, i) => {
                      return (
                        <BusinessDonationApprovalCard
                          item={el}
                          key={el.id}
                          onApprove={() => {
                            el.corporateStatus = 1;
                            approveBusinessDonation(el, selectedOrg);
                          }}
                          onDeny={() => denyBusinessDonation(el.id)}
                        />
                      );
                    })}
              </TableBody>
            )}
          </Table>
        </FullModal>
      )}
    </ShadowContainer>
  );
};

const styles = StyleSheet.create({
  activity: {
    width: "100%",
    height: "100%",
    position: "absolute",
    top: 0,
    bottom: 0,
    zIndex: 999,
  },
  container: {
    // flex: 1,
    height: "100%",
    width: "100%",
  },
  headerContainer: {
    flexDirection: "row",
  },
  infoContainer: {
    backgroundColor: COLORS.primary.white,
    marginHorizontal: 12,
    borderRadius: 12,
    paddingHorizontal: 20,
    paddingVertical: 12,
    alignItems: "center",
    justifyContent: "center",
  },
  info: {
    alignItems: "center",
    justifyContent: "center",
    marginHorizontal: 24,
  },
  title: {
    color: COLORS.primary.darkBlue,
  },
  URL: {
    fontSize: 20,
    fontWeight: 800,
    color: COLORS.primary.lightBlue,
  },
});
