export const COLORS = {
	primary: {
		blue_100: "#D6EFFF",
		lightBlue: "#1DA1F2",
		lightestBlue: "rgba(228, 245, 254, 1)",
		blue: "rgba(92, 137, 211, 1)",
		teal: "rgba(93,174,212,1)",
		darkBlue: "#001935",
		white: "#FFF",
		black: "#000",
		error: "#EE1D52",
		gray: "#999999",
		lightGray: "#C4C4C4",
		darkGray: "#444444",
		sunflower: "#FFE000",
		orange: "#FEAB09",
		lightOrange: "#F9CF93",
		green: "#B8D35E",
		offWhite: "#F6F6F6",
		neutral: "#FAFAFA",
		neutral_100: "#F6F6F6",
		neutral_200: "#EBEBEB",
		neutral_300: "#DEDEDE",
		neutral_400: "#C4C4C4",
		neutral_500: "#999999",
		neutral_600: "#737373",
		neutral_800: "#333333",
		stripe: "#635BFF"
	},
	secondary: {
		offWhite: "#F6F6F6",
		opaqueWhite: "rgba(255,255,255,0.95)",
		gray: "#828282"
	},
	background: {
		darkBlue: "#001935",
		lightBlue: "#1DA1F2",
		darkOrange: "#FEAB09",
		white: "#ffffff",
		offWhite: "rgba(255,255,255,0.95)",
		lightRed: "#FEEFEF"
	},
	text: {
		white: "#ffffff",
		lightBlue: "#1DA1F2",
		gray: "#828282",
		lightGray: "#C4C4C4",
		darkGrey: "#444444",
		error: "#EE1D52"
	}
};
