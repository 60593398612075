import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import { PrimaryText } from "components/Common/PrimaryText";
import { View } from "react-native-web";
import MuiButton from "components/Common/MuiButton";
import { CustomInput } from "components/Inputs/CustomInput";
import { CustomPicker } from "components/Inputs/CustomPicker";
import { COLORS } from "constants/Colors";
import { Box, Tabs, Tab } from "@mui/material";
import EmployeeDetailsCard from "components/Cards/Business/EmployeeDetailsCard";
import { NavHeader } from "components/Headers/NavHeader";
import { FiArchive, FiEdit, FiUpload } from "react-icons/fi";
import { useLocation, useNavigate } from "react-router-dom";
import { getUsersName } from "utils/helpers";
import { ProfilePic } from "components/Pics/ProfilePic";

const InformationCard = ({ title, details }) => (
  <>
    <PrimaryText fontSize={24} fontWeight={400}>
      {title}
    </PrimaryText>
    {details?.map((item, index) => (
      <Box key={index} mt={15} display="flex">
        <Box width={108}>
          <PrimaryText style={{ minWidth: 400 }} fontSize={20} fontWeight={400}>
            {item?.key}
          </PrimaryText>
        </Box>
        <PrimaryText
          fontSize={20}
          fontWeight={900}
          style={
            item?.key === "Email:" && {
              color: COLORS.primary.lightBlue,
              textDecoration: "underline",
            }
          }
        >
          {item?.value}
        </PrimaryText>
      </Box>
    ))}
  </>
);

export function EmployeeDetail(props) {
  const params = useLocation();
  const navigate = useNavigate();

  const selectedEmployee = params?.state?.item;
  const [selectedTab, setSelectedTab] = useState(0);

  console.log(params?.state);

  return (
    <>
      <View style={{ width: "100%" }}>
        <NavHeader title="Employee Name" onBack={() => navigate(-1)} />
      </View>

      <Grid container my={50} px={50}>
        <Grid item sm={12} md={3} mr={12}>
			<ProfilePic uri={selectedEmployee?.profilePic} size={250} />
        </Grid>
        <Grid item sm={12} md={8}>
          <Box mb={27}>
            <MuiButton
              sx={{ mr: 20 }}
              startIcon={<FiUpload />}
              variant="contained"
              title="Export Report"
            />
            <MuiButton
              sx={{ mr: 20 }}
              startIcon={<FiEdit />}
              variant="contained"
              title="Edit"
            />
            <MuiButton
              sx={{ mr: 20 }}
              startIcon={<FiArchive />}
              variant="contained"
              title="Archive"
              color="error"
            />
          </Box>

          <PrimaryText fontSize={32} fontWeight={900}>
            {getUsersName(selectedEmployee)}
          </PrimaryText>

          <Grid container mt={15}>
            <Grid item mr={100}>
              <InformationCard
                title="User Information"
                details={[
                  { key: "Role:", value: "Admin" },
                  { key: "Job Title:", value: selectedEmployee?.jobTitle },
                ]}
              />
            </Grid>
            <Grid item>
              <InformationCard
                title="Contact Information"
                details={[
                  { key: "Email:", value: selectedEmployee?.email },
                //   { key: "Phone:", value: "000–000–0000" },
                ]}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Box sx={style.tableContainer}>
        <PrimaryText fontSize={32} fontWeight={900}>
          History
        </PrimaryText>

        <Box style={style.tableHeader}>
          <CustomInput
            placeholder="Search"
            style={{ flexGrow: 1, maxWidth: 400 }}
            backgroundColor={COLORS.primary.white}
          />

          <PrimaryText
            fontSize={14}
            fontWeight={900}
            style={{
              color: COLORS.primary.darkGray,
              marginLeft: 24,
            }}
          >
            Sort By
          </PrimaryText>
          <CustomPicker
            placeholder="Recent"
            style={{ maxWidth: 170, marginLeft: 14 }}
            backgroundColor={COLORS.primary.white}
          />
        </Box>

        <Tabs
          value={selectedTab}
          sx={style.tabs}
          onChange={(_, value) => setSelectedTab(value)}
        >
          {["Donations", "Volunteering"].map((item, index) => (
            <Tab
              key={item}
              sx={style.tabItem({ isActive: index == selectedTab })}
              label={item}
            />
          ))}
        </Tabs>

        <EmployeeDetailsCard disableSelection={!selectedTab} />
      </Box>
    </>
  );
}

const style = {
  tableContainer: {
    width: "92%",
    bgcolor: "offwhite.main",
    borderRadius: "25px",
    py: 35,
    px: 60,
    display: "flex",
    flexDirection: "column",
  },
  tableHeader: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    marginTop: 32,
  },

  tabs: {
    borderBottom: 1,
    borderColor: "gray.lighter",
    width: "100%",
    marginTop: 30,
  },
  tabItem: ({ isActive }) => ({
    textTransform: "none",
    fontSize: 16,
    fontWeight: isActive ? 700 : 400,
    fontFamily: "Montserrat",
    width: "50%",
    "&:hover": {
      backgroundColor: "neutral.200",
    },
  }),
};
