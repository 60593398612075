import { StyleSheet, View, TouchableOpacity } from "react-native";
import React from "react";
import { COLORS } from "constants/Colors";
import { PrimaryText } from "components/Common/PrimaryText";
import { FaCcAmazonPay, FaCcAmex, FaCcApplePay, FaCcDinersClub, FaCcDiscover, FaCcJcb, FaCcMastercard, FaCcPaypal, FaCcStripe, FaCcVisa, FaCreditCard } from "react-icons/fa"

export default function PaymentMethodCard(props) {
  const {
    isSelected,
    onSelect,
    style: externalStyle,
    item,
    darkMode = true
  } = props;

  const { card, isDefault, } = item;
  const { brand, last4 } = card;

  //find credit card with regex
  let cardType = <FaCreditCard size={26}  />
  switch (brand) {
    case 'visa':
      cardType = <FaCcVisa size={26} />
      break;
    case 'mastercard':
      cardType = <FaCcMastercard size={26}  />
      break;
    case 'amex':
      cardType = <FaCcAmex size={26}  />
      break;
    case 'discover':
      cardType = <FaCcDiscover size={26}  />
      break;
    case 'diners':
      cardType = <FaCcDinersClub size={26}  />
      break;
    case 'jcb':
      cardType = <FaCcJcb size={26}  />
      break;
    case 'unionpay':
      cardType = <FaCcAmazonPay size={26}  />
      break;
    case 'applepay':
      cardType = <FaCcApplePay size={26}  />
      break;
    case 'paypal':
      cardType = <FaCcPaypal size={26}  />
      break;
    case 'stripe':
      cardType = <FaCcStripe size={26}  />
      break;
    default:
      cardType = <FaCreditCard size={26}  />
      break;
  }

  const style = styles(darkMode);

  const CreditCardIcon = () => {
    return (
      cardType
    )
  }

  return (
    <TouchableOpacity
      style={[style.container, isSelected && style.selected, externalStyle]}
      onPress={onSelect}
    >
      <CreditCardIcon size={24} />
      <View style={style.textsContainer}>
        <View style={style.row}>
          <PrimaryText fontSize={14} fontWeight={600} style={style.brand}>
            {brand}
          </PrimaryText>
          <PrimaryText fontSize={10} fontWeight={300}>
            xxxx-xxxx-xxxx-{last4}
          </PrimaryText>
        </View>

        {/* {isSelected && (
          <View style={[style.row, { marginTop: 4 }]}>
            <PrimaryText fontSize={10} fontWeight={300}>
              {name}
            </PrimaryText>
            {exp_month && exp_year && (
              <PrimaryText fontSize={10} fontWeight={300}>
                {exp_month}/{exp_year}
              </PrimaryText>
            )}
          </View>
        )} */}
      </View>
      {/* {rightIcon && <Feather name={rightIcon} size={24} />} */}
      {isDefault && (
        <View
          style={{
            position: "absolute",
            right: -1,
            top: -1,
            backgroundColor: COLORS.primary.lightBlue,
            borderTopRightRadius: 11,
            borderBottomLeftRadius: 11,
            paddingHorizontal: 12,
            paddingVertical: 2,
          }}
        >
          <PrimaryText
            fontSize={10}
            fontWeight={600}
            style={{ color: COLORS.primary.white }}
          >
            Default
          </PrimaryText>
        </View>
      )}
    </TouchableOpacity>
  );
}

const styles = (darkMode) =>
  StyleSheet.create({
    container: {
      // width: "80%",
      maxWidth: 300,
      borderRadius: 12,
      borderWidth: 1,
      borderColor: COLORS.primary.lightGray,
      backgroundColor: darkMode ? 'transparent' : COLORS.primary.white,
      flexDirection: "row",
      alignItems: "center",
      paddingLeft: 14,
      paddingRight: 18,
      marginBottom: 20,
      paddingVertical: 0,
      height: 60,
    },
    selected: {
      borderColor: COLORS.primary.lightBlue,
      backgroundColor: darkMode ? 'rgba(121, 196, 242, 0.35)' : COLORS.secondary.lightBlue,
      paddingVertical: 18,
      height: null,
    },
    textsContainer: {
      flex: 1,
      marginLeft: 12,
    },
    row: {
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      flex: 1,
    },
    brand: {
      textTransform: "capitalize",
    },
  });
