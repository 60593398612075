import React, { useState, createContext, useEffect, useContext } from "react";
import { listenToUser } from "services/user-service";
import { auth } from "utils/firebase";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [initializing, setInitializing] = useState(true);
  const [currentAuth, setCurrentAuth] = useState(null);
  const [currentUser, setCurrentUser] = useState(null);
  const [onboarding, setOnboarding] = useState(false);
  const [queryParams, setQueryParams] = useState(null);

  // Handle user state changes
  function onAuthStateChanged(auth) {
    setCurrentAuth(auth);
    if (initializing) setInitializing(false);
  }

  useEffect(() => {
    const subscriber = auth.onAuthStateChanged(onAuthStateChanged);
    return () => {
      setOnboarding(false);
      subscriber(); // unsubscribe on unmount
    };
  }, []);

  useEffect(() => {
    if (!currentAuth) {
      return setOnboarding(false);
    };

    const userSubscriber = listenToUser(currentAuth, setCurrentUser);

    return () => {
      setCurrentUser(null);
      userSubscriber();
    };
  }, [currentAuth]);

  if (initializing) return null;

  return (
    <AuthContext.Provider
      value={{
        initializing,
        currentAuth,
        setCurrentAuth,
        currentUser,
        setCurrentUser,
        onboarding, setOnboarding,
        queryParams, setQueryParams
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
