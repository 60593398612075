export const Links = {
	Landing: "/",
	CreateAccount: "/create-account",
	PersonalLogin: "/personal-login",
	CreatePersonalAccount: "/create-personal-account",
	VerifyPersonalAccount: "/verify-personal-account",
	EnterPasscode: "/enter-passcode",
	PersonalInformation: "/personal-information",
	Congrats: "/success-personal-account",
	CorporateAccount: "/corporate-account",
	AccountCreated: "/account-created",
	VerifyNonProfit: "/verify-non-profit",
	Verifying: "/verifying",
	AccountCreatedStep2: "/account-created-step2",

	Dashboard: "/dashboard",
	Team: "/team",

	Donations: "/donations",
	DonorDetails: "/donor-details",
	Fundraising: "/fundraising",
	FundraisingDetail: "/fundraisingDetail",
	CustomLinks: "/customLinks",
	CustomLinkDetails: "/customLinkDetails",
	UserDetails: "/userDetails",
	Events: "/events",
	EventDetail: "/eventDetail",
	Opportunities: "/opportunities",

	Settings: "/settings",
	Verification: "/verification",

	Support: "/support",

	//businesses
	BusinessDashboard: "/b-Dashboard",
	BusinessEmployees: "/employees",
	BusinessEmployeeDetail: "/employee-detail",
	BusinessDonations: "/b-donations",
	BusinessVolunteers: "/b-volunteers",
	BusinessCampaigns: "/b-campaigns",
	BusinessCampaignDetails: "/b-campaign-details",

	//nonprofits
	Nonprofits: "/nonprofits",
	NonprofitDetail: "/nonprofit-detail",

	GeneralSettings: "/b-settings",

	//businesses invite
	BusinessInviteLanding: "/invite-0",
	InvitePasscode: "/invite-1",
	InviteNewOrExistingAccount: "/invite-2",
	InviteNewAccount: "/invite-3",
	InviteExistingAccount: "/invite-4"
};
