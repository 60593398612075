import React, { useEffect, useState } from "react";
import { StyleSheet, View } from "react-native";
import { PrimaryText } from "components/Common/PrimaryText";
import { COLORS } from "constants/Colors";
import PrimaryButton from "components/Buttons/PrimaryButton";
import { CustomInput } from "components/Inputs/CustomInput";
import { Links } from "constants/Links";
import { DateInput } from "components/Inputs/DateInput";
import { useLocation, useNavigate } from "react-router-dom";
import {
	checkUsernameAvailability,
	createUser,
	createUserInDb,
	updateUsername
} from "services/user-service";
import { useLoading } from "context/LoadingContext";
import { auth } from "utils/firebase";
import {
	validateConfirmPassword,
	validateEmail,
	validatePassword,
	validatePhone
} from "utils/helpers";
import { organizationAddNewUser } from "services/organizations-service";
import firebase from "firebase/compat/app";
import {
	logout,
	signUpWithEmail,
	signUpWithEmailAndPassword
} from "services/auth-service";
import TermsAndPrivacyText from "components/Common/TermsAndPrivacyText";
import { useWindowDimensions } from "react-native-web";
import { BREAKPOINTS } from "utils/breakpoints";

export default function InviteNewAccount() {
	const { state = {} } = useLocation();
	const { invitation = {}, email } = state || {};
	const navigate = useNavigate();
	const { width } = useWindowDimensions();

	const [accountLinked, setAccountLinked] = useState(true);
	const { setLoading } = useLoading();

	const [inputs, setInputs] = useState({
		firstName: "",
		lastName: "",
		birthday: new Date("1990-01-02"),
		zipCode: "",
		username: "",
		password: "",
		confirmPassword: "",
		email
	});
	const [errors, setErrors] = useState({});

	const handleInputs = (key, e) => {
		const { value } = e.target;

		handleErrors(key, null);

		// console.log("key", key, value);

		setInputs((prev) => ({ ...prev, [key]: value }));
	};

	const handleChange = (key, e) => {
		const value = e.target.value;

		setErrors((prev) => ({ ...prev, [key]: null }));
		handleErrors("email", null);

		if (key === "email" && !validateEmail(value))
			handleErrors("email", "Please enter a valid email.");
		else if (key === "phone" && !validatePhone(value))
			handleErrors("phone", "Please enter a valid phone number.");
		else if (key === "password" && !validatePassword(value))
			handleErrors("password", "Password should be 6 character long.");
		else if (
			key === "confirmPassword" &&
			!validateConfirmPassword(inputs.password, value)
		)
			handleErrors("confirmPassword", "Your passwords doesn't match.");

		setInputs((prev) => ({ ...prev, [key]: value }));
	};

	const handleErrors = (key, value) =>
		setErrors((prev) => ({ ...prev, [key]: value }));

	const onCreate = async () => {
		let hasError = false;

		console.log("inputs", inputs);
		delete errors.email;
		Object.values(errors).forEach((value) => {
			if (value) {
				console.log("value", value, errors);
				hasError = true;
			}
		});

		Object.entries(inputs).forEach(([key, value]) => {
			if (!value) {
				handleErrors(key, "This field is required.");
				hasError = true;
			}
		});

		await checkUsernameAvailability(inputs.username, (err) => {
			if (err) {
				console.log("username error", err);
				handleErrors("username", err);
				hasError = true;
			}
		});

		console.log("hasError", hasError);

		if (hasError) return;

		const { email, phone = "", password, username, birthday } = inputs;

		setLoading(true);

		console.log("here", password);

		// createUser({
		//   email,
		//   phone,
		//   ...inputs
		// })

		signUpWithEmailAndPassword(
			email,
			password,
			() => {
				updateUsername(username, () =>
					createUserInDb({
						uid: firebase.auth().currentUser.uid,
						email,
						phone,
						...inputs,
						birthday: new Date(birthday),
						createdAt: new Date(),
						lastUpdate: new Date()
					})
						.then((_) => {
							// navigate(Links.Congrats)
							organizationAddNewUser(
								invitation.org,
								firebase.auth().currentUser.uid,
								"Employee",
								true,
								() => {
									setAccountLinked(true);
								}
							);
						})
						.catch((err) => {
							console.log(err);
							alert(err.message);
						})
				);
			},
			(err) => {
				console.log("error signing up", err);
				handleErrors("email", err.message);
				setLoading(false);
			}
		);
	};

	useEffect(() => {
		if (accountLinked) {
			setTimeout(() => {
				// navigate(Links.Dashboard)
				// window.location = "https://org.rayzeapp.com";
			}, 2000);
		}
	}, [accountLinked]);

	const styles = style({ width });

	return (
		<View style={styles.container}>
			{!accountLinked ? (
				<>
					<img
						src={require("assets/rayze.png")}
						width={width > BREAKPOINTS["sm"] ? 78 : 47}
					/>

					<PrimaryText
						fontSize={width > BREAKPOINTS["sm"] ? 54 : 32}
						fontWeight="900"
						style={{ marginTop: 31 }}
					>
						Create Your Account
					</PrimaryText>

					<PrimaryText
						fontSize={width > BREAKPOINTS["sm"] ? 32 : 20}
						style={{
							textAlign: "center",
							color: COLORS.primary.gray,
							marginTop: width > BREAKPOINTS["sm"] ? 14 : 26,
							marginBottom: width > BREAKPOINTS["sm"] ? 62 : 29
						}}
					>
						Enter Your Personal Information
					</PrimaryText>

					<View style={styles.row}>
						<CustomInput
							style={{
								width: "100%",
								maxWidth: 366,
								marginRight: width > BREAKPOINTS["sm"] ? 36 : 0,
								marginBottom: 20
							}}
							title="First Name"
							placeholder="Enter First Name"
							mandatory={true}
							onChange={(e) => handleInputs("firstName", e)}
							value={inputs?.firstName}
							errorMessage={errors?.firstName}
						/>

						<CustomInput
							style={{ width: "100%", maxWidth: 366, marginBottom: 20 }}
							title="Last Name"
							placeholder="Enter Last Name"
							mandatory={true}
							onChange={(e) => handleInputs("lastName", e)}
							value={inputs?.lastName}
							errorMessage={errors?.lastName}
						/>
					</View>

					<View style={styles.row}>
						<DateInput
							style={{
								width: "100%",
								maxWidth: 366,
								marginRight: width > BREAKPOINTS["sm"] ? 36 : 0,
								marginBottom: 20
							}}
							title="Date of Birth"
							placeholder="January 1990"
							mandatory={true}
							onChange={(e) => handleInputs("birthday", e)}
							value={inputs?.birthday}
							errorMessage={errors?.birthday}
							short
						/>

						<CustomInput
							title="Zip Code"
							placeholder="123456"
							mandatory={true}
							onChange={(e) => handleInputs("zipCode", e)}
							value={inputs?.zipCode}
							errorMessage={errors?.zipCode}
							style={{ width: "100%", maxWidth: 366, marginBottom: 20 }}
						/>
					</View>

					<View style={styles.row}>
						<CustomInput
							style={{
								width: "100%",
								maxWidth: 366,
								marginRight: width > BREAKPOINTS["sm"] ? 36 : 0,
								marginBottom: 20
							}}
							title="Username"
							placeholder="Enter username"
							mandatory={true}
							onChange={(e) => handleInputs("username", e)}
							value={inputs?.username}
							errorMessage={errors?.username}
						/>

						<CustomInput
							style={{ width: "100%", maxWidth: 366, marginBottom: 20 }}
							title="Email"
							placeholder="user@corp.com"
							mandatory={true}
							onChange={(e) => handleInputs("email", e)}
							value={inputs?.email}
							errorMessage={errors?.email}
						/>
					</View>

					<View style={styles.separator} />

					<View style={styles.row}>
						<CustomInput
							style={{
								width: "100%",
								maxWidth: 366,
								marginRight: width > BREAKPOINTS["sm"] ? 36 : 0,
								marginBottom: 20
							}}
							title="Password"
							mandatory
							placeholder="Enter password*"
							onChange={(e) => handleChange("password", e)}
							value={inputs?.password}
							errorMessage={errors?.password || errors?.email}
							secure={true}
						/>

						<CustomInput
							style={{ width: "100%", maxWidth: 366, marginBottom: 20 }}
							title="Confirm Password"
							mandatory
							placeholder="Re-enter password*"
							onChange={(e) => handleChange("confirmPassword", e)}
							value={inputs?.confirmPassword}
							errorMessage={errors?.confirmPassword}
							secure={true}
						/>
					</View>

					<PrimaryButton
						title="Create Account"
						style={{ marginTop: 40, marginBottom: 40 }}
						onPress={onCreate}
					/>

					<TermsAndPrivacyText style={{ position: "relative", bottom: null }} />
				</>
			) : (
				<>
					<View style={styles.logoContainer}>
						<img
							src={
								width > BREAKPOINTS["sm"]
									? require("assets/rayzeLogo.png")
									: require("assets/rayze.png")
							}
							width={width > BREAKPOINTS["sm"] ? 200 : 48}
						/>
					</View>

					<View style={styles.congratsContainer}>
						<img
							src={
								invitation?.org?.profilePic ||
								require("assets/misc/financialLogo.png")
							}
							width={width > BREAKPOINTS["sm"] ? 104 : 88}
							style={{ marginBottom: 27 }}
						/>

						<PrimaryText
							fontSize={width > BREAKPOINTS["sm"] ? 32 : 24}
							fontWeight={width > BREAKPOINTS["sm"] ? 900 : 400}
							style={{ color: COLORS.primary.darkBlue, textAlign: "center" }}
						>
							Congrats! Your account has been linked.
						</PrimaryText>

						{width < BREAKPOINTS["sm"] && (
							<PrimaryButton
								title="Download App"
								style={{ width: "100%", maxWidth: 250, marginTop: 55 }}
							/>
						)}
					</View>
				</>
			)}
		</View>
	);
}

const style = ({ width }) =>
	StyleSheet.create({
		container: {
			backgroundColor: COLORS.primary.white,
			justifyContent: width > BREAKPOINTS["sm"] ? "center" : "flex-start",
			alignItems: "center",
			paddingVertical: 75,
			width: "100%",
			height: "100%",
			paddingTop: width > BREAKPOINTS["sm"] ? 0 : 19
		},
		row: {
			width: "100%",
			maxWidth: 768,
			flexDirection: width > BREAKPOINTS["sm"] ? "row" : "column",
			alignItems: "center"
		},
		separator: {
			width: 143,
			height: 1,
			backgroundColor: COLORS.primary.neutral_500,
			marginTop: 22,
			marginBottom: 48
		},
		logoContainer: {
			position: "fixed",
			width: "100%",
			justifyContent: "center",
			alignItems: "center",
			top: width > BREAKPOINTS["sm"] ? 100 : 20
		},
		congratsContainer: {
			flex: 1,
			justifyContent: "center",
			alignItems: "center"
		}
	});
