import React from "react";
import { StyleSheet } from "react-native";
import { COLORS } from "constants/Colors";
import { TouchableOpacity } from "react-native-web";
import { PrimaryText } from "./PrimaryText";

export const Button = (props) => {
  const { title, iconLeft, iconRight, onPress, style = {}, disabled } = props;
  return (
    <TouchableOpacity
      style={[styles.button, { ...style }]}
      onPress={onPress}
      disabled={disabled}
    >
      {iconLeft && iconLeft}
      <PrimaryText
        fontSize={16}
        fontWeight={800}
        style={[
          { color: COLORS.primary.white, marginRight: iconRight ? 12 : 0 },
          style.text && { ...style.text },
        ]}
      >
        {title}
      </PrimaryText>
      {iconRight && iconRight}
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  title: {
    color: COLORS.primary.lightBlue,
  },
  button: {
    flexDirection: "row",
    backgroundColor: COLORS.primary.lightBlue,
    justifyContent: "center",
    alignItems: "center",
    alignSelf: "flex-start",
    paddingHorizontal: 20,
    paddingVertical: 6.5,
    borderRadius: 20,
  },
});
