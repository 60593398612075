import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/compat/functions";
import "firebase/compat/auth";
import "firebase/compat/storage";

const firebaseConfig = {
	apiKey: "AIzaSyD8l8wI8grW58akgBO-yR8XnwsIUxp_5aM",
	authDomain: "rayze-d94c7.firebaseapp.com",
	projectId: "rayze-d94c7",
	storageBucket: "rayze-d94c7.appspot.com",
	messagingSenderId: "252364688731",
	appId: "1:252364688731:web:1da8999a74fc1f49759e7e",
	measurementId: "G-D2VKF5FNLF",
  }

const app = firebase.initializeApp(firebaseConfig);

const firestore = app.firestore();
const auth = app.auth();
const storage = app.storage();
const functions = app.functions();

export { firestore, auth, storage, functions };
