import React, { useEffect, useState } from "react";
import {
	StyleSheet,
	View,
	Image,
	Text,
	ActivityIndicator,
	TouchableOpacity
} from "react-native";
import { COLORS } from "constants/Colors";
import { CustomInput } from "components/Inputs/CustomInput";
import { login, sendPasswordReset } from "services/auth-service";
import { PrimaryText } from "components/Common/PrimaryText";
import PrimaryButton from "components/Buttons/PrimaryButton";
import { Links } from "constants/Links";
import { useLocation, useNavigate } from "react-router-dom";
import { updateUser } from "services/user-service";
import firebase from "firebase/compat/app";
import { organizationAddNewUser } from "services/organizations-service";
import { useWindowDimensions } from "react-native-web";
import { BREAKPOINTS } from "utils/breakpoints";
import TermsAndPrivacyText from "components/Common/TermsAndPrivacyText";

export function InviteExistingAccount() {
	const { width } = useWindowDimensions();
	const navigate = useNavigate();
	const { state = {} } = useLocation();
	const { invitation = {} } = state || {};

	const [email, setEmail] = useState(""); //jim@tracknicity.com
	const [password, setPassword] = useState(""); //0522MAy!
	const [emailError, setEmailError] = useState(null);
	const [passwordError, setPasswordError] = useState(null);
	const [loginDisabled, setLoginDisabled] = useState(true);
	const [loading, setLoading] = useState(false);

	const [accountLinked, setAccountLinked] = useState(false);

	useEffect(() => {
		setEmailError(null);
		setPasswordError(null);

		//check valid email here
		checkIfLoginActive();
	}, [email, password]);

	useEffect(() => {
		setLoading(false);
	}, [emailError, passwordError]);

	const checkIfLoginActive = () => {
		if (email !== "" && password !== "") {
			setLoginDisabled(false);
		} else {
			setLoginDisabled(true);
		}
	};

	const loginPressed = () => {
		setLoading(true);
		login(email, password, setEmailError, setPasswordError, () => {
			setLoading(false);
			organizationAddNewUser(
				invitation.org,
				firebase.auth().currentUser.uid,
				"Employee",
				true,
				() => {
					setAccountLinked(true);
				}
			);
		});
	};

	useEffect(() => {
		if (accountLinked) {
			setTimeout(() => {
				// navigate(Links.Dashboard)
				window.location = "https://org.rayzeapp.com";
			}, 2000);
		}
	}, [accountLinked]);

	function forgotPasswordPressed() {
		const email = window.prompt("Enter your email address");
		if (email) {
			sendPasswordReset(email);
		}
	}

	const styles = style({ width });

	return (
		<View style={styles.container}>
			{!accountLinked ? (
				<>
					<img
						src={require("assets/rayze.png")}
						width={width > BREAKPOINTS["sm"] ? 78 : 47}
					/>

					<PrimaryText
						fontSize={width > BREAKPOINTS["sm"] ? 54 : 32}
						fontWeight={900}
						style={{ color: COLORS.primary.darkBlue, marginTop: 31 }}
					>
						Link Your Account
					</PrimaryText>

					<PrimaryText
						fontSize={width > BREAKPOINTS["sm"] ? 32 : 20}
						fontWeight={400}
						style={{
							textAlign: "center",
							color: COLORS.primary.gray,
							marginBottom: 68,
							marginTop: width > BREAKPOINTS["sm"] ? 14 : 51
						}}
					>
						Enter Your Personal Information
					</PrimaryText>

					<CustomInput
						title="Email Address"
						mandatory
						placeholder="Enter email*"
						value={email}
						errorMessage={emailError}
						onChangeText={(e) => setEmail(e)}
						style={{ width: "100%", maxWidth: 536, marginBottom: 34 }}
					/>

					<CustomInput
						title="Password"
						placeholder="Enter password*"
						value={password}
						errorMessage={passwordError}
						onChangeText={(e) => setPassword(e)}
						secure={true}
						mandatory={true}
						style={{ width: "100%", maxWidth: 536 }}
					/>

					<TouchableOpacity
						onPress={forgotPasswordPressed}
						style={styles.forgotPasswordBtn}
					>
						<PrimaryText
							fontSize={width > BREAKPOINTS["sm"] ? 16 : 14}
							fontWeight={500}
							style={styles.forgotPassword}
						>
							Forgot Password?
						</PrimaryText>
					</TouchableOpacity>

					<PrimaryButton
						title="Link Account"
						onPress={loginPressed}
						disabled={loginDisabled}
						style={{ width: "100%", maxWidth: 250 }}
					/>

					<TermsAndPrivacyText style={{ marginTop: "10%", marginBottom: 0 }} />
				</>
			) : (
				<>
					<View style={styles.logoContainer}>
						<img
							src={
								width > BREAKPOINTS["sm"]
									? require("assets/rayzeLogo.png")
									: require("assets/rayze.png")
							}
							width={width > BREAKPOINTS["sm"] ? 200 : 48}
						/>
					</View>

					<View style={styles.congratsContainer}>
						<img
							src={
								invitation?.org?.profilePic ||
								require("assets/misc/financialLogo.png")
							}
							width={width > BREAKPOINTS["sm"] ? 104 : 88}
							style={{ marginBottom: 27 }}
						/>

						<PrimaryText
							fontSize={width > BREAKPOINTS["sm"] ? 32 : 24}
							fontWeight={width > BREAKPOINTS["sm"] ? 900 : 400}
							style={{ color: COLORS.primary.darkBlue, textAlign: "center" }}
						>
							Congrats! Your account has been linked.
						</PrimaryText>

						{width < BREAKPOINTS["sm"] && (
							<PrimaryButton
								title="Download App"
								style={{ width: "100%", maxWidth: 250, marginTop: 55 }}
							/>
						)}
					</View>
				</>
			)}

			{loading && (
				<ActivityIndicator
					animating={loading}
					size={"large"}
					style={StyleSheet.absoluteFill}
					color={COLORS.primary.blue}
				/>
			)}
		</View>
	);
}

const style = ({ width }) =>
	StyleSheet.create({
		container: {
			backgroundColor: COLORS.primary.white,
			justifyContent: width > BREAKPOINTS["sm"] ? "center" : "flex-start",
			alignItems: "center",
			width: "100%",
			height: "100vh",
			paddingTop: width > BREAKPOINTS["sm"] ? 0 : 19
		},

		forgotPasswordBtn: {
			width: "100%",
			marginTop: 9,
			maxWidth: 536,
			marginBottom: width > BREAKPOINTS["sm"] ? 95 : 56
		},
		forgotPassword: {
			color: COLORS.primary.lightBlue,
			textAlign: "right"
		},
		logoContainer: {
			position: "fixed",
			width: "100%",
			justifyContent: "center",
			alignItems: "center",
			top: width > BREAKPOINTS["sm"] ? 100 : 20
		},
		congratsContainer: {
			flex: 1,
			justifyContent: "center",
			alignItems: "center"
		}
	});
