import { CATEGORIES } from "assets/categories";
import { Links } from "./Links";

export const ROLES = [
	{
		value: "Leader",
		label: "Admin"
	},
	{
		value: "Employee",
		label: "User"
	}
];

export const POSITIONS = [
	{
		value: "staff",
		label: "Staff"
	},
	{
		value: "volunteer",
		label: "Volunteer"
	},
	{
		value: "board",
		label: "Board"
	}
];

export const DONATION_STATUSES = [
	{
		value: 0,
		label: "Awaiting Approval"
	},
	{
		value: 1,
		label: "Approved"
	},
	{
		value: 2,
		label: "Denied"
	}
];

export const TOP_CAUSE_COLORS = [
	"linear-gradient(270.04deg, #1DA1F2 0.03%, #46D7F7 98.22%)",
	"linear-gradient(270deg, #FFE000 0%, #FFF84D 100%)",
	"linear-gradient(269.89deg, #F35A3B 0.1%, #FFA572 97.04%)",
	"linear-gradient(270deg, #EE1D52 0%, #F55CA6 100%)",
	"linear-gradient(270deg, #98D15E 0%, #9BF2A4 100%)"
];

export const MONTHS = [
	{
		value: "January",
		label: "January"
	},
	{
		value: "February",
		label: "February"
	},
	{
		value: "March",
		label: "March"
	},
	{
		value: "April",
		label: "April"
	},
	{
		value: "May",
		label: "May"
	},
	{
		value: "June",
		label: "June"
	},
	{
		value: "July",
		label: "July"
	},
	{
		value: "August",
		label: "August"
	},
	{
		value: "September",
		label: "September"
	},
	{
		value: "October",
		label: "October"
	},
	{
		value: "November",
		label: "November"
	},
	{
		value: "December",
		label: "December"
	}
];

export const TIPS = {
	sidebar: {
		title: "This is your sidebar",
		description: (
			<>
				Here you can access all the different <b>pages</b> of your companies
				site.
			</>
		),
		nextPath: Links.BusinessDashboard
	},
	[Links.BusinessDashboard]: {
		title: "Dashboard",
		description: (
			<>
				Shows your an <b>overview</b> of your companies giving trends and
				employee involvement
			</>
		),
		nextPath: Links.BusinessDonations
	},
	[Links.BusinessDonations]: {
		title: "Donations",
		description: (
			<>
				Let’s you see all donations <b>employees</b> or the company have made as
				well as any donations that are pending.
			</>
		),
		nextPath: Links.BusinessCampaigns
	},
	[Links.BusinessCampaigns]: {
		title: "Campaigns",
		description: (
			<>
				This is where you will set up <b>giving campaigns</b> for your
				employees. Here you can determine what <b>causes</b> the campaign will
				support and how much it is looking to <b>raise</b>.
			</>
		),
		nextPath: Links.BusinessEmployees
	},
	[Links.BusinessEmployees]: {
		title: "Employees",
		description: (
			<>
				<b>Invite</b> new employees and set up their <b>profile</b> for
				corporate matching.
			</>
		),
		nextPath: Links.GeneralSettings
	},
	[Links.GeneralSettings]: {
		title: "Settings",
		description: (
			<>
				This is where you can determine <b>admin</b> settings as well as default
				campaign settings like <b>supported nonprofits</b> and
				<b>employee match</b> amount.
			</>
		),
		nextPath: Links.BusinessDashboard,
		buttonTitle: "Finish",
		hideIcon: true
	},

	// Nonprofit and community group Sidebar Tips
	[Links.Dashboard]: {
		title: "Dashboard",
		description: (
			<>
				Shows you an <b>overview</b> of your nonprofits information and
				fundraising
			</>
		),
		nextPath: Links.Team
	},
	[Links.Team]: {
		title: "Team",
		description: (
			<>
				<b>Invite</b> new team members and set up their <b>position</b> and
				<b> information</b>
			</>
		),
		nextPath: Links.Events
	},
	[Links.Events]: {
		title: "Events",
		description: (
			<>
				This is where you will set up <b>events</b> for volunteers to attend.
				Here you can determine the <b>date, time, and location</b> of each event
				you wish to run.
			</>
		),
		nextPath: Links.Donations
	},
	[Links.Donations]: {
		title: "Donations",
		description: (
			<>
				Let’s you see all donations <b>individual</b> donors or <b>companies</b>{" "}
				have made if it’s a recurring or one-time donation.
			</>
		),
		nextPath: Links.Settings
	},
	[Links.Settings]: {
		title: "General Settings",
		description: (
			<>
				This is where you can update <b>information</b> about your nonprofit as
				well as update your <b>profile, cover photo and landing page.</b>.
			</>
		),
		nextPath: Links.Verification
	},
	[Links.Verification]: {
		title: "Verification",
		description: (
			<>
				Here you can <b>upload</b> documents for the{" "}
				<b>Rayze verification process</b> and check the status of your
				verification.
			</>
		),
		nextPath: Links.Dashboard,
		buttonTitle: "Finish",
		hideIcon: true
	}
};

export const NONPROFIT_TIPS = {
	sidebar: {
		title: "This is your sidebar",
		description: (
			<>
				Here you can access all the different <b>pages</b> of your nonprofits
				site.
			</>
		),
		nextPath: Links.BusinessDashboard
	},
	[Links.Dashboard]: {
		title: "Dashboard",
		description: (
			<>
				Shows you an <b>overview</b> of your nonprofits information and
				fundraising
			</>
		),
		nextPath: Links.Donations
	},
	[Links.Donations]: {
		title: "Donations",
		description: (
			<>
				Let’s you see all donations <b>individual</b> donors or <b>companies</b>{" "}
				have made if it’s a recurring or one-time donation.
			</>
		),
		nextPath: Links.Fundraising
	},
	[Links.Fundraising]: {
		// Champions
		title: "Champions",
		description: (
			<>
				Rayze Champions are <b>individual philanthropists</b> who have chosen to raise funds and awareness for your organization.
			</>
		),
		nextPath: Links.CustomLinks
	},
	[Links.CustomLinks]: {
		title: "Custom Links",
		description: (
			<>
				Create <b>specific links</b> for individual causes or areas of your
				nonprofit allowing for a more <b>personalized donation experience</b>{" "}
				for your supporters.
			</>
		),
		nextPath: Links.Events
	},
	[Links.Events]: {
		title: "Events",
		description: (
			<>
				This is where you will set up <b>events</b> for volunteers to attend.
				Here you can determine the <b>date, time, and location</b> of each event
				you wish to run.
			</>
		),
		nextPath: Links.Team
	},
	[Links.Team]: {
		title: "Team",
		description: (
			<>
				<b>Invite</b> new team members and set up their <b>position</b> and
				<b> information</b>
			</>
		),
		nextPath: Links.Settings
	},
	[Links.Settings]: {
		title: "General Settings",
		description: (
			<>
				This is where you can update <b>information</b> about your nonprofit as
				well as update your <b>profile, cover photo and landing page.</b>.
			</>
		),
		nextPath: Links.Verification
	},
	[Links.Verification]: {
		title: "Verification",
		description: (
			<>
				Here you can <b>upload</b> documents for the{" "}
				<b>Rayze verification process</b> and check the status of your
				verification.
			</>
		),
		nextPath: Links.Dashboard,
		buttonTitle: "Finish",
		hideIcon: true
	}
};

export const COMMUNITY_TIPS = {
	sidebar: {
		title: "This is your sidebar",
		description: (
			<>
				Here you can access all the different <b>pages</b> of your companies
				site.
			</>
		),
		nextPath: Links.Dashboard
	},
	[Links.Dashboard]: {
		title: "Dashboard",
		description: (
			<>
				Shows you an <b>overview</b> of your community group information and
				fundraising
			</>
		),
		nextPath: Links.Team
	},
	[Links.Team]: {
		title: "Team",
		description: (
			<>
				<b>Invite</b> new team members and set up their <b>position</b> and
				<b> information</b>
			</>
		),
		nextPath: Links.Events
	},
	[Links.Events]: {
		title: "Events",
		description: (
			<>
				This is where you will set up <b>events</b> for volunteers to attend.
				Here you can determine the <b>date, time, and location</b> of each event
				you wish to run.
			</>
		),
		nextPath: Links.Settings
	},
	[Links.Settings]: {
		title: "General Settings",
		description: (
			<>
				This is where you can update <b>information</b> about your community
				group as well as update your <b>profile or cover</b> photo.
			</>
		),
		nextPath: Links.Dashboard,
		buttonTitle: "Finish",
		hideIcon: true
	}
};

export const creditCardTypes = [
	{
		name: "Visa",
		id: "visa",
		fontAwesome: "cc-visa"
	},
	{
		name: "MasterCard",
		id: "mastercard",
		fontAwesome: "cc-mastercard"
	},
	{
		name: "American Express",
		id: "amex",
		fontAwesome: "cc-amex"
	},
	{
		name: "Discover",
		id: "discover",
		fontAwesome: "cc-discover"
	},
	{
		name: "Diners Club",
		id: "diners",
		fontAwesome: "cc-diners-club"
	},
	{
		name: "Paypal",
		id: "paypal",
		fontAwesome: "cc-paypal"
	},
	{
		name: "JCB",
		id: "jcb",
		fontAwesome: "cc-jcb"
	}
];

export const causes = [
	{
		name: "Advocacy & Human Rights",
		icon: CATEGORIES.advocacyAndHumanRights,
		value: "advocacyAndHumanRights",
		rayze: "0mNWIUcsfd4uFAki7f5v"
	},
	{
		name: "Animals",
		icon: CATEGORIES.animals,
		value: "animals",
		rayze: "d2mjsgjn7ukY1PcCPQlP"
	},
	{
		name: "Arts & Culture",
		icon: CATEGORIES.artsAndCulture,
		value: "artsAndCulture",
		rayze: "eewKdVWG1zdqOBqd5dPm"
	},
	{
		name: "Board Development",
		icon: CATEGORIES.boardDevelopment,
		value: "boardDevelopment",
		rayze: "B65zjcqauZzcpKa4rkis"
	},
	{
		name: "Children & Youth",
		icon: CATEGORIES.childrenAndYouth,
		value: "childrenAndYouth",
		rayze: "KBfmPxnjaXjOHV5eAJEr"
	},
	{
		name: "Community",
		icon: CATEGORIES.community,
		value: "community",
		rayze: "9ieKJ3nVIN9U7CneEqCn"
	},
	{
		name: "Computers & Technology",
		icon: CATEGORIES.computersAndTechnology,
		value: "computersAndTechnology",
		rayze: "ntTwbh5VgKyfgKJaTJQ0"
	},
	{
		name: "Crisis Support",
		icon: CATEGORIES.crisisSupport,
		value: "crisisSupport",
		rayze: "3lKw8JAeqr3oyRL4j5DF"
	},
	{
		name: "Disabled",
		icon: CATEGORIES.disabled,
		value: "disabled",
		rayze: "86jBt5Z6EeqQwjr3NMVI"
	},
	{
		name: "Disaster Relief",
		icon: CATEGORIES.disasterRelief,
		value: "disasterRelief",
		rayze: "hEoYYdtdE85TOjNYjbwL"
	},
	{
		name: "Education & Literacy",
		icon: CATEGORIES.educationAndLiteracy,
		value: "educationAndLiteracy",
		rayze: "1QoBZg7ML5UbNYyvpUt6"
	},
	{
		name: "Emergency & Safety",
		icon: CATEGORIES.emergencyAndSafety,
		value: "emergencyAndSafety"
	},
	{
		name: "Employment",
		icon: CATEGORIES.employment,
		value: "employment",
		rayze: "YgaqEnunUIQY0zrPSJmn"
	},
	{
		name: "Environment",
		icon: CATEGORIES.environment,
		value: "environment",
		rayze: "DI2egMicfTJrmRZFRPFW"
	},
	{
		name: "LGBTQ+",
		icon: CATEGORIES.gayLesbianBiTrans,
		value: "gayLesbianBiTrans",
		rayze: "rM9PDNsMxnVXXrxsRYrw"
	},
	{
		name: "Health & Medicine",
		icon: CATEGORIES.healthAndMedicine,
		value: "healthAndMedicine",
		rayze: "yUGJwAM4Dq3WW8t5hT6H"
	},
	{
		name: "Homeless & Housing",
		icon: CATEGORIES.homelessAndHousing,
		value: "homelessAndHousing",
		rayze: "Hjs122nHX1QAhN8tuSCZ"
	},
	{
		name: "Hunger",
		icon: CATEGORIES.hunger,
		value: "hunger",
		rayze: "drEdJrUVsh93Z4Lbkb5p"
	},
	{
		name: "Immigrants & Refugees",
		icon: CATEGORIES.immigrantsAndRefugees,
		value: "immigrantsAndRefugees",
		rayze: "UrewqL9KOE8hFtnqTjW1"
	},
	{
		name: "International",
		icon: CATEGORIES.international,
		value: "international"
	},
	{
		name: "Justice & Legal",
		icon: CATEGORIES.justiceAndLegal,
		value: "justAndLegal",
		rayze: "KP1VWJLUVdCy2OYd6HOP"
	},
	{
		name: "Media & Broadcasting",
		icon: CATEGORIES.mediaAndBroadcasting,
		value: "mediaAndBroadcasting",
		rayze: "MVfUfdUQAI1GBHLJTrdM"
	},
	{
		name: "Mentoring",
		icon: CATEGORIES.mentoring,
		value: "mentoring",
		rayze: "SKStnGudqOvYbwsWY8Ya"
	},
	{
		name: "Mental Health & Therapy",
		icon: CATEGORIES.mentalHealth,
		value: "mentalHealth",
		rayze: "6XYrN2f6fKkNVV2jWhsH"
	},
	{
		name: "Music/Music Therapy",
		icon: CATEGORIES.music,
		value: "music",
		rayze: "Fju15Ja5e9cO6LqzHSep"
	},
	{
		name: "Politics",
		icon: CATEGORIES.politics
	},
	{
		name: "Race & Ethnicity",
		icon: CATEGORIES.raceAndEthnicity,
		value: "raceAndEthnicity",
		rayze: "aTbyca9hJK4KxnZkaldo"
	},
	{
		name: "Religion",
		icon: CATEGORIES.religion,
		value: "religion",
		rayze: "RpmhOZd5abLIehcF92Nj"
	},
	{
		name: "Seniors",
		icon: CATEGORIES.seniors,
		value: "seniors",
		rayze: "TklcvF0VYU0Ijwawmiep"
	},
	{
		name: "Sports & Recreation",
		icon: CATEGORIES.sportsAndRecreation,
		value: "sportsAndRecreation",
		rayze: "lGjC4plOBGu9s9kGtrqt"
	},
	{
		name: "Veterans & Military Families",
		icon: CATEGORIES.veteransAndMilitaryFamilies,
		value: "veteransAndMilitaryFamilies",
		rayze: "ZGUjKTjRMZmNJ9AjVXgi"
	},
	{
		name: "Voting/Voter Registration",
		icon: CATEGORIES.voting,
		value: "voterRegistration",
		rayze: "7PnyGQAjrgY3J12SU2yD"
	},
	{
		name: "Women",
		icon: CATEGORIES.women,
		value: "women",
		rayze: "4Y7wDwGEmuzOJhC9GLu2"
	},
	{
		name: "Men",
		icon: CATEGORIES.men,
		value: "men",
		rayze: "zITyhJK7hT6zlcg1wj0Q"
	}
];
