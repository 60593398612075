import React, { useEffect, useState } from "react";
import Modal from "@mui/material/Modal";
import { PrimaryText } from "components/Common/PrimaryText";
import {
  Box,
  IconButton,
  Table,
  TableBody,
} from "@mui/material";
import { ActivityIndicator, Image, TouchableOpacity, View } from "react-native-web";
import { COLORS } from "constants/Colors";
import { useBusiness } from "context/Business/BusinessContext";
import PaymentMethodCard from "components/Cards/PaymentMethodCard";
import { Button } from "components/Common/Button";
import { useOrganization } from "context/OrganizationContext";
import { deletePaymentMethod, updateDefaultPaymentMethod } from "services/stripe-service";
import { FaEdit } from "react-icons/fa";
import { ConfirmModal } from "./Confirmation";
import { EditPaymentOptionModal } from "./EditPaymentOptionModal";
import { FiEdit } from "react-icons/fi";

export function PaymentMethodModal(props) {
  const { isVisible, setIsVisible, onAdd } = props;

  const { paymentMethods, fetchPaymentMethods } = useBusiness();
  const { selectedOrg } = useOrganization();

  const [loading, setLoading] = useState(false);
  const [selectedMethod, setSelectedMethod] = useState(null);
  const [paymentOptionModal, setPaymentOptionModal] = useState(false);

  function makeDefaultPressed() {
    setPaymentOptionModal(false);
    setLoading(true);
    paymentMethods.forEach((method) => {
      if (method.id === selectedMethod?.id) {
        method.isDefault = true;
      } else {
        method.isDefault = false;
      }
    })
    updateDefaultPaymentMethod(
      selectedMethod?.id,
      selectedOrg?.stripeCustomerId,
      onSuccess
    );
  }

  function deletePaymentMethodPressed() {
    setPaymentOptionModal(false);
    setLoading(true);
    //remove method
    const findIndex = paymentMethods.findIndex((method) => method.id === selectedMethod?.id);
    paymentMethods.splice(findIndex, 1);
    deletePaymentMethod(selectedMethod?.id, onSuccess);
  }

  async function onSuccess() {
    setLoading(true);
    await fetchPaymentMethods();
    setLoading(false);
  }



  return (
    <Modal open={isVisible} >
      <Box sx={style.modalBox}>
        <PrimaryText fontSize="24" fontWeight="900">
          Payment Methods
        </PrimaryText>

        <IconButton
          sx={style.close}
          onClick={() => setIsVisible(false)}
        >
          <img src={require("assets/misc/close.png")} />
        </IconButton>

        <PrimaryText fontSize={16} fontWeight={900} style={{ alignText: 'left', alignSelf: 'flex-start', marginVertical: 30 }}>
          Saved Methods
        </PrimaryText>


        <Table
          className="table"
          style={{ borderTopLeftRadius: 12, overflow: "hidden" }}
        >
          <TableBody>
            {paymentMethods.sort((a, b) => a.isDefault ? -1 : 1).map((item, i) => {
              return (
                <View style={style.cardContainer} key={item?.card?.last4}>
                  <PaymentMethodCard

                    // isSelected={selectedMethod?.id === item?.id}
                    onSelect={() => setSelectedMethod(item)}
                    item={item}
                    style={{ width: "100%" }}

                  />
                  <TouchableOpacity
                    style={style.editBtn}
                    onPress={() => {
                      setSelectedMethod(item)
                      setPaymentOptionModal(true)
                    }}
                  >
                    <FiEdit
                      size={20}
                      color={COLORS.primary.darkBlue}
                    />
                  </TouchableOpacity>
                </View>
              )
            })}
          </TableBody>
        </Table>

        <TouchableOpacity 
        style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginVertical: 12, width: '100%', marginBottom: 30 }}
        onPress={onAdd}
        >
          <Image
            source={require("assets/misc/plusCircle.png")}
            style={{ marginRight: 4, width: 20, height: 24, resizeMode: 'contain' }}

          />
          <PrimaryText
            fontSize={24}
            fontWeight={900}
            style={{ color: COLORS.primary.lightBlue }}
          >
            Add New
          </PrimaryText>
        </TouchableOpacity>


        <View style={{ flexDirection: 'row', justifyContent: 'center', marginVertical: 12, width: '100%', marginBottom: 30 }}>
          {/* <Button
            title={"Cancel"}
            style={{
              backgroundColor: 'transparent',
              borderColor: COLORS.primary.black,
              borderWidth: 1,
              // paddingVertical: 4,
              text: {
                fontSize: 14,
                color: COLORS.primary.black
              }
            }}
            onPress={() => setIsVisible(false)}
          /> */}
          <Button
            title={"Done"}
            style={{
              backgroundColor: COLORS.primary.lightBlue,
              // paddingVertical: 4,
              text: {
                fontSize: 14
              },
              // opacity: donateDisabled ? 0.5 : 1,
            }}
            onPress={() => setIsVisible(false)}
          // disabled={donateDisabled}
          />
        </View>


        {loading && (
          <ActivityIndicator
            size="large"
            color={COLORS.primary.darkGray}
            style={{ marginTop: 20, position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}
            animating={loading}
          />
        )}

        {paymentOptionModal && (
          <EditPaymentOptionModal
            isVisible={paymentOptionModal}
            setIsVisible={setPaymentOptionModal}
            title={`Card Ending in ${selectedMethod?.card?.last4}`}
            isDefault={selectedMethod?.isDefault}
            onRemove={deletePaymentMethodPressed}
            onDefault={makeDefaultPressed}
          />
        )}

      </Box>
    </Modal >
  );
}

const style = {
  modalBox: {
    minWidth: 340,
    // maxWidth: 400,
    minHeight: "20vh",
    bgcolor: "offwhite.main",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    borderRadius: "25px",
    pt: 35,
    paddingX: 60,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  modalHeader: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    marginTop: 32,
  },
  tabBox: {
    display: "flex",
    justifyContent: "flex-start",
    width: "100%",
    marginTop: 20,
  },
  tabs: {
    borderBottom: 1,
    borderColor: "gray.lighter",
    width: "100%",
    // marginTop: 30,
  },
  tabItem: ({ width, isActive }) => ({
    textTransform: "none",
    fontSize: 16,
    fontWeight: isActive ? 700 : 400,
    fontFamily: "Montserrat",
    width,
    "&:hover": {
      backgroundColor: "neutral.200",
    },
  }),
  close: { position: "absolute", right: 56, top: 23 },
  cardContainer: {
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  editBtn: { paddingBottom: 20, marginLeft: 12 },
};
