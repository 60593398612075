import React, { useState, createContext, useEffect, useContext } from "react";
import { getMatchedDonations, getRecentBusinessDonations } from "services/businesses/business-donations-service";
import { useOrganization } from "../OrganizationContext";

export const BusinessDonationsContext = createContext();

export const BusinessDonationsProvider = ({ children }) => {
  const { selectedOrg, users } = useOrganization();

  const [recentDonations, setRecentDonations] = useState([]);
  const [matchedDonations, setMatchedDonations] = useState([]);
  const [getDonations, setGetDonations] = useState(false);
  const [ourDonations, setOurDonations] = useState([]);
  const [ourRecurringDonations, setOurRecurringDonations] = useState([]);
  const [selectedDonation, setSelectedDonation] = useState(null);
  const [topDonors, setTopDonors] = useState([]);
  const [topNonprofits, setTopNonprofits] = useState([]);
  const [topCauses, setTopCauses] = useState([]);

  //stats
  const [totalDonationsYTD, setTotalDonationsYTD] = useState(0);
  const [totalMatchedDonationsYTD, setTotalMatchedDonationsYTD] = useState(0);
  const [totalHoursVolunteeredYTD, setTotalHoursVolunteeredYTD] = useState(0);
  const [totalNonprofitsDonatedToYTD, setTotalNonprofitsDonatedToYTD] =
    useState(0);

  useEffect(() => {
    if (!selectedOrg || !users) return;
    const sub = getRecentBusinessDonations(
      selectedOrg,
      setRecentDonations,
      users
    );
    const matchedSub = getMatchedDonations(
      selectedOrg,
      setMatchedDonations,
      users
    );

    return () => {
      sub();
      matchedSub();
    };
  }, [selectedOrg, users]);

  useEffect(() => {
    //get top employee donors by sum of donations
    if (recentDonations?.length < 1) {
      setTotalNonprofitsDonatedToYTD(0);
      setTotalDonationsYTD(0);
      setTopDonors([]);
      setTopNonprofits([]);
      setTopCauses([]);
      return;
    }
    const topDonors = Object.values(
      recentDonations.reduce((a, e) => {
        const userId = e?.userId;
        a[userId] ??= { ...e, userId, amount: 0 };
        a[userId].amount += e?.amount;
        return a;
      }, {})
    );
    topDonors.sort((a, b) => (a.amount > b.amount ? -1 : 1));

    const topNonprofits = Object.values(
      recentDonations.reduce((a, e) => {
        const orgId = e?.orgId;
        a[orgId] ??= { ...e, orgId, amount: 0 };
        a[orgId].amount += e?.amount;

        a[orgId].backers === undefined
          ? (a[orgId].backers = 1)
          : a[orgId].backers++;
        return a;
      }, {})
    );
    topNonprofits.sort((a, b) => (a.amount > b.amount ? -1 : 1));

    let allCauses = [];
    recentDonations.forEach((x) => {
      const causes = x?.organization?.causes;
      causes &&
        causes.forEach((cause) => {
          allCauses.push({ causeId: cause, total: 1 });
        });
    });
    //count causes
    const topCauses = Object.values(
      allCauses.reduce((a, e) => {
        const causeId = e.causeId;
        a[causeId] ??= { ...e, causeId, total: 0 };
        a[causeId].total += e.total;
        return a;
      }, {})
    );
    topCauses.sort((a, b) => (a.total > b.total ? -1 : 1));
    topCauses.forEach((x) => {
      x.percent = (x.total / allCauses.length) * 100;
    });

    setTotalNonprofitsDonatedToYTD(topNonprofits.length);

    const thisYearsDonations = recentDonations.filter(x => x.date.toDate().getFullYear() === new Date().getFullYear());
    setTotalDonationsYTD(thisYearsDonations.reduce((a, e) => a + e?.amount, 0));

    setTopDonors(topDonors.slice(0, 10));
    setTopNonprofits(topNonprofits.slice(0, 5));
    setTopCauses(topCauses.slice(0, 5));
  }, [recentDonations]);

  useEffect(() => {
    //get matched donations YTD
    if (!matchedDonations) return;
    let matchedDonationsYTD = matchedDonations.filter(
      (x) => x.date.toDate().getFullYear() === new Date().getFullYear()
    );
    setTotalMatchedDonationsYTD(
      matchedDonationsYTD.reduce((a, e) => a + e?.amount, 0)
    );
    // console.log('hjere', matchedDonationsYTD)
  }, [matchedDonations]);

  return (
    <BusinessDonationsContext.Provider
      value={{
        recentDonations,
        setGetDonations,
        ourDonations,
        ourRecurringDonations,
        selectedDonation,
        setSelectedDonation,
        topDonors,
        topNonprofits,
        topCauses,
        totalDonationsYTD,
        totalMatchedDonationsYTD,
        totalHoursVolunteeredYTD,
        totalNonprofitsDonatedToYTD,
      }}
    >
      {children}
    </BusinessDonationsContext.Provider>
  );
};

export const useBusinessDonations = () => useContext(BusinessDonationsContext);
