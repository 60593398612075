import {
	Table,
	TableCell,
	TableHead,
	TableRow,
	TableBody
} from "@mui/material";
import { PrimaryText } from "components/Common/PrimaryText";
import { COLORS } from "constants/Colors";
import React from "react";
import styled from "styled-components";

const TableCellCenter = styled(TableCell)`
	text-align: center !important;
`;

const TableCellRight = styled(TableCell)`
	text-align: right !important;
`;

export default function EmployeeDetailsCard() {
	return (
		<Table sx={{ mt: "64px" }}>
			<TableHead>
				<TableRow>
					<TableCellCenter>
						<PrimaryText style={styles.heading}>Nonprofit</PrimaryText>
					</TableCellCenter>

					<TableCellCenter>
						<PrimaryText style={styles.heading}>Date</PrimaryText>
					</TableCellCenter>

					<TableCellCenter>
						<PrimaryText style={styles.heading}>Frequency</PrimaryText>
					</TableCellCenter>

					<TableCellRight>
						<PrimaryText style={styles.heading}>Amount given</PrimaryText>
					</TableCellRight>

					<TableCellRight>
						<PrimaryText style={styles.heading}>Company match</PrimaryText>
					</TableCellRight>
					<TableCellRight>
						<PrimaryText style={styles.heading}>Total</PrimaryText>
					</TableCellRight>
				</TableRow>
			</TableHead>

			<TableBody>
				<TableRow>
					<TableCellCenter>
						<PrimaryText>Trevor Project</PrimaryText>
					</TableCellCenter>

					<TableCellCenter>
						<PrimaryText>00/00/0000</PrimaryText>
					</TableCellCenter>

					<TableCellCenter>
						<PrimaryText>Monthly</PrimaryText>
					</TableCellCenter>

					<TableCellRight>
						<PrimaryText>$200</PrimaryText>
					</TableCellRight>

					<TableCellRight>
						<PrimaryText>$200</PrimaryText>
					</TableCellRight>

					<TableCellRight>
						<PrimaryText>$400</PrimaryText>
					</TableCellRight>
				</TableRow>
			</TableBody>
		</Table>
	);
}

const styles = {
	heading: {
		textTransform: "uppercase",
		color: COLORS.background.lightBlue,
		fontSize: 16,
		fontWeight: 700
	}
};
