export const CATEGORIES = {
	advocacyAndHumanRights: require("./advocacy.png"),
	animals: require("./animal.png"),
	artsAndCulture: require("./artsCulture.png"),
	boardDevelopment: require("./broadDevelopment.png"),
	childrenAndYouth: require("./children.png"),
	community: require("./community.png"),
	computersAndTechnology: require("./computerTech.png"),
	crisisSupport: require("./crisisSupport.png"),
	disabled: require("./peopleWithDisabilities.png"),
	disasterRelief: require("./disasterRelief.png"),
	educationAndLiteracy: require("./education.png"),
	emergencyAndSafety: require("./emergency.png"),
	employment: require("./employment.png"),
	environment: require("./environment.png"),
	gayLesbianBiTrans: require("./lgbtq.png"),
	healthAndMedicine: require("./healthAndMedicine.png"),
	homelessAndHousing: require("./homelessness.png"),
	hunger: require("./hunger.png"),
	immigrantsAndRefugees: require("./immigration.png"),
	international: require("./international.png"),
	justiceAndLegal: require("./justiceLegal.png"),
	mediaAndBroadcasting: require("./media.png"),
	men: require("./men.png"),
	mentalHealth: require("./mentalHealth.png"),
	mentoring: require("./mentoring.png"),
	music: require("./music.png"),
	politics: require("./politics.png"),
	raceAndEthnicity: require("./raceEthnicity.png"),
	religion: require("./faith.png"),
	seniors: require("./seniors.png"),
	sportsAndRecreation: require("./sports.png"),
	veteransAndMilitaryFamilies: require("./veteransMilitary.png"),
	voting: require("./voting.png"),
	women: require("./women.png")
};
